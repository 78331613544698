// @import "./bounce.scss";

#profile_pic {
    width: 256px;
    height: 256px;
      /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -webkit-border-radius: 50%; 

  /* Firefox 1-3.6 */
  -moz-border-radius: 50%; 
  
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 50%; 
}
.glow img{
  @keyframes border-pulsate {
      0%{
          box-shadow:none;
      }
      60%{
          box-shadow:
              inset 0 0 50px #fff,
              inset 20px 0 80px #f5f,
              inset -20px 0 80px #0ff,
              inset 20px 0 300px #f0f,
              inset -20px 0 300px #0ff,
              0 0 50px #fff,
              -10px 0 80px #f0f,
              10px 0 80px #0ff;
      }
      100%{
          box-shadow:none;
      }
  }
//   -webkit-animation: bounce-in-fwd 1.1s cubic-bezier(0.645, 0.045, 0.355, 1.000) both;
//   animation: bounce-in-fwd 1.1s cubic-bezier(0.645, 0.045, 0.355, 1.000) both;
  animation: bounce-in 800ms cubic-bezier(0.645, 0.045, 0.355, 1.000) both, border-pulsate 10s infinite;
  /* transition:all 4s ease-out; 
  /*animation-delay: 0s;
  animation-play-state: running; 
  // animation-fill-mode: backwards; */
}
