@import "https://fonts.googleapis.com/css?family=Average+Sans|Contrail+One";
body, html, .container {
  -webkit-user-select: none;
  user-select: none;
  background-color: #1a1e23;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.welcome_text {
  color: #fff;
  -webkit-user-select: text;
  user-select: text;
  font-family: Contrail One;
}

.standard_text {
  color: #fff;
  -webkit-user-select: text;
  user-select: text;
  font-family: Average Sans;
}

.container {
  text-align: center;
  position: relative;
}

.container:before {
  content: "";
  vertical-align: middle;
  width: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.pod {
  vertical-align: middle;
  display: inline-block;
}

.pod a {
  margin: 2px;
  font-size: 120%;
}

.build_text {
  color: #fff;
  -webkit-user-select: text;
  user-select: text;
  margin: .2rem;
  font-family: Average Sans;
  text-decoration: none;
}

.cookie {
  text-decoration: none !important;
}

.no_hightnight a:link, a {
  color: inherit;
  text-decoration: underline;
}

.cookiesncoco {
  font-size: 1rem;
}

.name-letters {
  padding: 0;
}

#profile_pic {
  border-radius: 50%;
  width: 256px;
  height: 256px;
}

.glow img {
  animation: .8s cubic-bezier(.645, .045, .355, 1) both bounce-in, 10s infinite border-pulsate;
}

@keyframes border-pulsate {
  0% {
    box-shadow: none;
  }

  60% {
    box-shadow: inset 0 0 50px #fff, inset 20px 0 80px #f5f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff, 0 0 50px #fff, -10px 0 80px #f0f, 10px 0 80px #0ff;
  }

  100% {
    box-shadow: none;
  }
}

footer {
  text-align: center;
  background-color: #111417;
  padding: .4rem;
  animation: .8s cubic-bezier(.25, .46, .45, .94) 2s both footer-slide;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: scale(0);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  55% {
    animation-timing-function: ease-in;
    transform: scale(.7);
  }

  72% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  81% {
    animation-timing-function: ease-in;
    transform: scale(.84);
  }

  89% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }

  95% {
    animation-timing-function: ease-in;
    transform: scale(.95);
  }

  100% {
    animation-timing-function: ease-out;
    transform: scale(1);
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  10.1% {
    opacity: 1;
  }

  10.2% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  20.1% {
    opacity: 1;
  }

  20.6% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  30.1% {
    opacity: 1;
  }

  30.5% {
    opacity: 1;
  }

  30.6% {
    opacity: 0;
  }

  45% {
    opacity: 0;
  }

  45.1% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  55.1% {
    opacity: 0;
  }

  57% {
    opacity: 0;
  }

  57.1% {
    opacity: 1;
  }

  60% {
    opacity: 1;
  }

  60.1% {
    opacity: 0;
  }

  65% {
    opacity: 0;
  }

  65.1% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  75.1% {
    opacity: 0;
  }

  77% {
    opacity: 0;
  }

  77.1% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  85.1% {
    opacity: 0;
  }

  86% {
    opacity: 0;
  }

  86.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.text-flicker {
  animation: 1.5s both text-flicker;
}

.text-flicker-2 {
  animation: 1.5s 1s both text-flicker;
}

@-webkit-keyframes footer-slide {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes footer-slide {
  0% {
    opacity: 0;
    transform: translateY(1000px);
  }

  100% {
    opacity: 1;
  }
}
/*# sourceMappingURL=index.31300052.css.map */
